/* footer */
.footer-block {
    background-color: #617c40;
    @include layout(1300) {
        padding: 0 30px;
    }
    @include layout(767) {
        padding: 0 20px;
    }
}
.footer-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    @include layout(767) {
        height: 30px;
    }
}
.social-icons {
    a {
        display: inline-block;
        cursor: pointer;
        @include layout(1100) {
            & > img {
                width: 30px;
                height: 30px;
            }
        }
        @include layout(767) {
            margin-top: 2px;
            & > img {
                width: 25px;
                height: 25px;
            }
        }
        & + a {
            margin-left: 25px;
            @include layout(767) {
                margin-left: 10px;
            }
        }
    }
}
.copy-right {
    font-size: 16px;
    color: #f4f1e9;
    font-family: $reg;
    padding-left: 80px;
    @include layout(1280) {
        padding-left: 0;
    }
    @include layout(767) {
        display: none;
    }
}
.mobile-copy-right {
    display: none;
    font-size: 10px;
    color: #f4f1e9;
    font-family: $reg;
    @include layout(767) {
        display: block;
    }
}