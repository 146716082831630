/* services styles */
.services-us {
    display: flex;
    justify-content: flex-end;
    @include layout(1100) {
        justify-content: center;
    }
    @include layout(767) {
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
    }
}
.services-us-content {
    width: 300px;
    margin-right: 130px;
    margin-top: 165px;
    @include layout(1200) {
        margin-right: 30px;
        padding-left: 30px;
    }
    @include layout(767) {
        margin-right: 0;
        margin-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .section-header {
        border-bottom: 1px solid #7daa48;
        display: flex;
        align-items: flex-end;
        h4 {
            font-size: 40px;
            color: #5a5e56;
            font-family: $reg;
            display: inline-block;
            @include layout(1100) {
                font-size: 30px;
            }
            @include layout(767) {
                font-size: 21px;
                padding-bottom: 5px;
            }
        }
        img {
            display: inline-block;
            position: relative;
            left: -40px;
            @include layout(767) {
                width: 60px;
                height: 60px;
                left: -20px;
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        color: #585858;
        font-family: $reg;
        @include layout(767) {
            font-size: 14px;
            line-height: 18px;
        }
    }
}
.section-sub-header {
    font-size: 16px;
    color: #585858;
    font-family: $bold;
    margin-top: 30px;
    @include layout(767) {
        margin-top: 20px;
    }
}
.section-list {
    li {
        font-size: 16px;
        font-family: $reg;
        color: #585858;
        margin-top: 6px;
        @include layout(767) {
            font-size: 14px;
            margin-top: 3px;
        }
    }
}
.services-us-media-block {
    width: 745px;
    background-color: #eaf2a9;
    overflow: hidden;
    padding-bottom: 45px;
    margin-top: -1px;
    @include layout(1100) {
        width: 430px;  
    }
    @include layout(767) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 40px;
        padding-top: 10px;
    }
}
.services-media-content {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &+ .services-media-content {
        @include layout(767) {
            margin-top: 50px;
        }
    }
    @include layout(767) {
        margin-top: 0;
        flex-direction: row-reverse;
        max-width: 320px;
        margin: auto;
    }
}
.services-content-wrap {
    margin-left: 80px;
    @include layout(1100) {
        margin-left: 20px;
    }
    @include layout(767) {
        margin-left: 15px;
    }
    h4 {
        font-size: 25px;
        color: #7daa48;
        font-family: $reg;
        @include layout(1100) {
            font-size: 22px;
        }
        @include layout(767) {
            font-size: 16px;
        }
    }
    p {
        max-width: 266px;
        margin-top: 5px;
        color: #5e5d5d;
        font-size: 20px;
        font-family: $reg;
        line-height: 24px;
        @include layout(1100) {
            font-size: 16px;
        }
        @include layout(767) {
            font-size: 13px;
            line-height: 16px;
        }
    }
}
.services-content-image {
    margin-right: 120px;
    &.vlsi {
        margin-right: 100px;
        @include layout(1100) {
            margin-right: 30px;
        }
        @include layout(767) {
            margin-right: 0;
        }
    }
    &.gaming {
        margin-right: 140px;
        @include layout(1100) {
            margin-right: 30px;
        }
        @include layout(767) {
            margin-right: 0;
        }
    }
    @include layout(1100) {
        margin-right: 30px;
        margin-left: 20px;
    }
    @include layout(767) {
        margin-right: 0;
        width: 100px;
    }
    img {
        @include layout(1100) {
            width: 110px;
        }
        @include layout(767) {
            width: 95%;
        }
    }
}
