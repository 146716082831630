html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

select,input,
option,
textarea {
	font-family: $reg;
	font-size: 12px;
}


/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

body {
	font-family: $reg;
	font-weight: 400;
	font-style: normal;
	font-size: 14px;
	margin: 0;
	padding: 0;
	background-color: #fff;
	-webkit-tap-highlight-color: transparent;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote,q{
	&:before,
	&:after {
		content: '';
		content: none;
	}
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

.button {
	width: 100%;
	height: 45px;
	line-height: 45px;
	padding: 0 10px;
	background: #192431;
	font-family: $reg;
	font-weight: 400;
	font-style: normal;
	font-size: 24px;
	border: none;
	color: #fff;
	cursor: pointer;
	&:focus {
		outline: none;
	}
}

/*reset inputs*/
input,
select,
button {
	-webkit-tap-highlight-color: transparent;
	&:focus {
		outline: none;
	}
}

input[type="text"],
input[type="password"] {
	font-family: $reg;
	font-weight: 400;
	font-style: normal;
	font-size: 14px;
	border: none;
	width: 100%;
	background: transparent;
	&:focus {
		outline: none;
	}
}

textarea {
	resize: none;
	&:focus {
		outline: none;
	}
}

.error {
	display: none;
	color: #ff0000;
	font-size: 12px;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

svg {
	max-width: 100%;
	max-height: 100%;
	vertical-align: top;
}

a {
	text-decoration: none;
	font-family: $reg;
	color: #7c7c7c;
	&:focus {
		outline: none;
	}
	img {
		border: none;
		outline: none;
	}
}

input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

select {
    appearance: none;
}
select::-ms-expand {
    display: none;
}