/* solutions styles */
.approach-us {
    display: flex;
    justify-content: flex-end;
    @include layout(1100) {
        justify-content: center;
    }
    @include layout(767) {
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
    }
}
.approach-us-content {
    width: 300px;
    margin-right: 130px;
    margin-top: 150px;
    @include layout(1200) {
        margin-right: 30px;
        padding-left: 30px;
    }
    @include layout(767) {
        margin-right: 0;
        margin-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .section-header {
        border-bottom: 1px solid #7daa48;
        display: flex;
        align-items: flex-end;
        h4 {
            font-size: 40px;
            color: #5a5e56;
            font-family: $reg;
            display: inline-block;
            @include layout(1100) {
                font-size: 30px;
            }
            @include layout(767) {
                font-size: 21px;
                padding-bottom: 5px;
            }
        }
        img {
            display: inline-block;
            position: relative;
            left: -40px;
            @include layout(767) {
                width: 60px;
                height: 60px;
                left: -20px;
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        color: #585858;
        font-family: $reg;
        @include layout(767) {
            font-size: 14px;
            line-height: 18px;
        }
    }
}
.approach-us-media-block {
    width: 745px;
    background-color: #d3e6b3;
    position: relative;
    overflow: hidden;
    margin-top: -1px;
    @include layout(1100) {
        width: 430px;  
    }
    @include layout(767) {
        width: 100%;
    }
}
.approach-point-header {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    img {
        width: 41px;
        height: 28px;
        display: inline-block;
        vertical-align: middle;
        &.big {
            width: 45px;
            height: 37px;
        }
    }
    h4 {
        font-size: 24px;
        vertical-align: middle;
        color: #534d4d;
        font-family: $reg;
        display: inline-block;
        margin-left: 15px;
        @include layout(1100) {
            font-size: 20px;
        }
        @include layout(767) {
            font-size: 17px;
        }
    }
}
.approach-bridge {
    margin-left: 120px;
    margin-top: 5px;
    margin-bottom: 5px;
    @include layout(767) {
        margin-left: 85px;
        margin-top: 0;
        margin-bottom: 0;
    }
    img {
        display: inline-block;
        vertical-align: middle;
        width: 2px;
    }
    p {
        color: #534d4d;
        font-size: 18px;
        font-family: $reg;
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        @include layout(1100) {
            max-width: 220px;
            font-size: 14px;
            margin-left: 10px;
        }
        @include layout(767) {
            font-size: 12px;
            margin-left: 8px;
            max-width: 130px;
        }
    }
}
.approach-group {
    margin-left: 235px;
    padding-top: 45px;
    padding-bottom: 20px;
    @include layout(1100) {
        margin-left: 35px;
    }
    @include layout(767) {
        margin-left: 28px;
        width: 270px;
        margin: auto;
        padding-top: 15px;
    }
}