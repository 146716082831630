/* solutions styles */
.solutions-us {
    display: flex;
    justify-content: flex-end;
    @include layout(1100) {
        justify-content: center;
    }
    @include layout(767) {
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
    }
}
.solutions-us-content {
    width: 300px;
    margin-right: 130px;
    margin-top: 160px;
    @include layout(1200) {
        margin-right: 30px;
        padding-left: 30px;
    }
    @include layout(767) {
        margin-right: 0;
        margin-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .section-header {
        border-bottom: 1px solid #7daa48;
        display: flex;
        align-items: flex-end;
        h4 {
            font-size: 40px;
            color: #5a5e56;
            font-family: $reg;
            display: inline-block;
            @include layout(1100) {
                font-size: 30px;
            }
            @include layout(767) {
                font-size: 21px;
                padding-bottom: 5px;
            }
        }
        img {
            display: inline-block;
            position: relative;
            left: -40px;
            @include layout(767) {
                width: 60px;
                height: 60px;
                left: -20px;
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        color: #585858;
        font-family: $reg;
        @include layout(767) {
            font-size: 14px;
            line-height: 18px;
        }
    }
}
.solutions-us-media-block {
    width: 745px;
    background-color: #b7e0dd;
    position: relative;
    overflow: hidden;
    font-size: 0;
    margin-top: -1px;
    @include layout(1100) {
        width: 430px;  
    }
    @include layout(767) {
        width: 100%;
        text-align: center;
    }
    img {
        @include layout(1100) {
            width: 430px;
        }
        @include layout(767) {
            width: 320px;
        }
    }
}
.solutions-point {
    text-align: center;
    label {
        display: block;
        font-size: 20px;
        color: #5e5d5d;
        font-family: $reg;
        margin-top: 10px;
        @include layout(1100) {
            width: 130px;
            font-size: 16px;
        }
        @include layout(767) {
            font-size: 8px;
            width: 100%;
        }
    }
    @include layout(1100) {
        & > img {
            width: 50px;
        }
    }
    &.point1 {
        margin-left: 155px;
        @include layout(1100) {
            margin-left: 55px;
        }
        @include layout(767) {
            margin-left: 68px;
        }
    }
    &.point2 {
        margin-right: 80px;
        @include layout(1100) {
            margin-right: 33px;
        }
        @include layout(767) {
            margin-right: 34px; 
        }
    }
    &.point3 {
        margin-left: 290px;
        @include layout(1100) {
            margin-left: 150px;
        }
        @include layout(767) {
            margin-left: 126px;
        }
    }
    &.point4 {
        margin-left: 125px;
        @include layout(1100) {
            margin-left: 55px;
        }
        @include layout(767) {
            margin-left: 60px;
        }
    }
    &.point5 {
        margin-right: 130px;
        @include layout(1100) {
            margin-right: 40px;
        }
        @include layout(767) {
            margin-right: 55px;
        }
    }
    &.point6 {
        margin-left: 330px;
        @include layout(1100) {
            margin-left: 156px;
        }
        @include layout(767) {
            margin-left: 138px;
        }
    }
}
.solutions-media-points-group {
    position: absolute;
    top: 134px;
    left: 0;
    right: 0;
    @include layout(1100) {
        top: 84px;
    }
    @include layout(767) {
        top: 60px;
        max-width: 320px;
        margin: auto;
    }
}
.solutions-point-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 90px;
    @include layout(1100) {
        margin-bottom: 20px;
    }
    @include layout(767) {
        margin-bottom: 20px;
    }
}