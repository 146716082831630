html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

select, input,
option,
textarea {
  font-family: lato-regular;
  font-size: 12px;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  font-family: lato-regular;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  margin: 0;
  padding: 0;
  background-color: #fff;
  -webkit-tap-highlight-color: transparent;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.button {
  width: 100%;
  height: 45px;
  line-height: 45px;
  padding: 0 10px;
  background: #192431;
  font-family: lato-regular;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  border: none;
  color: #fff;
  cursor: pointer;
}

.button:focus {
  outline: none;
}

/*reset inputs*/
input,
select,
button {
  -webkit-tap-highlight-color: transparent;
}

input:focus,
select:focus,
button:focus {
  outline: none;
}

input[type="text"],
input[type="password"] {
  font-family: lato-regular;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  border: none;
  width: 100%;
  background: transparent;
}

input[type="text"]:focus,
input[type="password"]:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea:focus {
  outline: none;
}

.error {
  display: none;
  color: #ff0000;
  font-size: 12px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

svg {
  max-width: 100%;
  max-height: 100%;
  vertical-align: top;
}

a {
  text-decoration: none;
  font-family: lato-regular;
  color: #7c7c7c;
}

a:focus {
  outline: none;
}

a img {
  border: none;
  outline: none;
}

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

select {
  appearance: none;
}

select::-ms-expand {
  display: none;
}

@font-face {
  font-family: lato-regular;
  src: url("../fonts/lato-regular.eot");
  src: url("../fonts/lato-regular.eot") format("embedded-opentype"), url("../fonts/lato-regular.woff2") format("woff2"), url("../fonts/lato-regular.woff") format("woff"), url("../fonts/lato-regular.ttf") format("truetype"), url("../fonts/lato-regular.svg#lato-regular") format("svg");
}

@font-face {
  font-family: lato-light;
  src: url("../fonts/lato-light.eot");
  src: url("../fonts/lato-light.eot") format("embedded-opentype"), url("../fonts/lato-light.woff2") format("woff2"), url("../fonts/lato-light.woff") format("woff"), url("../fonts/lato-light.ttf") format("truetype"), url("../fonts/lato-light.svg#lato-light") format("svg");
}

@font-face {
  font-family: lato-bold;
  src: url("../fonts/lato-bold.eot");
  src: url("../fonts/lato-bold.eot") format("embedded-opentype"), url("../fonts/lato-bold.woff2") format("woff2"), url("../fonts/lato-bold.woff") format("woff"), url("../fonts/lato-bold.ttf") format("truetype"), url("../fonts/lato-bold.svg#lato-bold") format("svg");
}

@font-face {
  font-family: raleway-medium;
  src: url("../fonts/raleway-medium.eot");
  src: url("../fonts/raleway-medium.eot") format("embedded-opentype"), url("../fonts/raleway-medium.woff2") format("woff2"), url("../fonts/raleway-medium.woff") format("woff"), url("../fonts/raleway-medium.ttf") format("truetype"), url("../fonts/raleway-medium.svg#raleway-medium") format("svg");
}

@font-face {
  font-family: raleway-semibold;
  src: url("../fonts/raleway-semibold.eot");
  src: url("../fonts/raleway-semibold.eot") format("embedded-opentype"), url("../fonts/raleway-semibold.woff2") format("woff2"), url("../fonts/raleway-semibold.woff") format("woff"), url("../fonts/raleway-semibold.ttf") format("truetype"), url("../fonts/raleway-semibold.svg#raleway-semibold") format("svg");
}

/* common styles start */
.restrict-width {
  max-width: 1280px;
  margin: 0 auto;
}

.padding-lr {
  padding: 0 40px;
}

@media (max-width: 1024px) {
  .padding-lr {
    padding: 0 30px;
  }
}

@media (max-width: 1023px) {
  .padding-lr {
    padding: 0 20px;
  }
}

.mr-left-0 {
  margin-left: 0 !important;
}

.mr-right-0 {
  margin-right: 0 !important;
}

.pad-left-0 {
  padding-left: 0 !important;
}

.pad-right-0 {
  padding-right: 0 !important;
}

.verticalM {
  display: inline-block;
  vertical-align: middle;
}

.verticalT {
  display: inline-block;
  vertical-align: top;
}

.verticalB {
  display: inline-block;
  vertical-align: bottom;
}

.hide {
  display: none;
}

.show {
  display: inline-block;
}

.v-hidden {
  visibility: hidden;
}

.v-show {
  visibility: visibility;
}

button {
  appearance: none;
  outline: none;
  border: none;
  font-family: inherit;
  line-height: 1;
  cursor: pointer;
}

.buttons-block {
  display: flex;
}

.btn-fill {
  color: #fff;
  font-size: 14px;
  background-color: #71afe0;
  padding: 12px 35px;
  border-radius: 4px;
}

.btn-text {
  color: #71afe0;
  font-size: 14px;
  background-color: transparent;
  margin-left: 20px;
}

/* custom checkbox styles start */
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
}

.checkbox-block {
  margin-bottom: 20px;
  width: 250px;
}

.checkbox-block span {
  display: inherit;
  padding-left: 26px;
  color: #929ba5;
  font-size: 12px;
}

.checkbox-label {
  color: #504b4a;
  font-size: 16px;
}

input[type="checkbox"] {
  width: inherit;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.6em;
  cursor: pointer;
  line-height: 14px;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: url("../images/checkbox-1.svg");
  position: absolute;
  left: 0;
  top: 0;
  width: 14px;
  height: 14px;
  /* width: 1.25em; height: 1.25em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1); */
}

/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: url("../images/checkbox-2.svg");
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  transition: all .2s;
}

/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

/* custom checkbox styles end */
/* common styles end */
/* header styles*/
.logo-block a {
  display: inline-block;
}

@media (max-width: 767px) {
  .logo-block a img {
    width: 108px;
    height: 25px;
  }
}

.hamburger-block {
  padding: 10px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .hamburger-block img {
    width: 15px;
    height: 14px;
  }
}

.header-group {
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 40px 30px;
}

@media (max-width: 767px) {
  .header-group {
    padding: 15px 10px 10px 20px;
    align-items: center;
  }
}

.menu-list {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: #57595f;
  width: 370px;
}

.menu-list.show {
  display: block;
}

@media (max-width: 767px) {
  .menu-list {
    position: fixed;
    height: 100%;
    overflow: auto;
  }
}

.menu-list ul {
  padding-top: 105px;
  padding-bottom: 50px;
  padding-left: 100px;
}

@media (max-width: 767px) {
  .menu-list ul {
    padding-top: 75px;
  }
}

.menu-list ul li {
  font-size: 20px;
  color: #89b259;
  font-family: lato-regular;
  padding: 14px 0;
}

@media (max-width: 767px) {
  .menu-list ul li {
    font-size: 16px;
  }
}

.menu-list ul li.active {
  color: #fafbf8;
  border-right: 8px solid #fff;
}

.menu-list ul li + li {
  margin-top: 25px;
}

@media (max-width: 767px) {
  .menu-list ul li + li {
    margin-top: 5px;
  }
}

.menu-list .menu-close {
  width: 18px;
  height: 18px;
  padding: 20px;
  box-sizing: content-box;
  position: absolute;
  right: 40px;
  top: 30px;
}

@media (max-width: 767px) {
  .menu-list .menu-close {
    right: 10px;
    top: 10px;
  }
}

.menu-list .menu-close img {
  width: 100%;
}

/* hero styles */
.hero-block {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

@media (max-width: 1100px) {
  .hero-block {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .hero-block {
    justify-content: flex-end;
    flex-direction: column-reverse;
    align-items: center;
  }
}

.hero-content {
  margin-top: 165px;
  padding-left: 78px;
}

@media (max-width: 1100px) {
  .hero-content {
    padding-right: 40px;
    padding-left: 30px;
    width: 330px;
    margin-top: 95px;
  }
}

@media (max-width: 767px) {
  .hero-content {
    margin-top: 25px;
    width: auto;
  }
}

.hero-content h4 {
  font-size: 40px;
  color: #57595f;
  font-family: raleway-semibold;
  line-height: 44px;
  border-bottom: 2px solid #7daa48;
  padding-bottom: 8px;
}

@media (max-width: 1100px) {
  .hero-content h4 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .hero-content h4 {
    font-size: 22px;
    line-height: 26px;
  }
}

@media (max-width: 480px) {
  .hero-content h4 {
    padding-bottom: 3px;
  }
}

.hero-content p {
  font-size: 36px;
  color: #7daa48;
  font-family: raleway-medium;
  margin-top: 6px;
}

@media (max-width: 767px) {
  .hero-content p {
    font-size: 18px;
    line-height: 18px;
  }
}

.hero-media img {
  display: block;
  width: 745px;
}

@media (max-width: 1100px) {
  .hero-media img {
    width: 430px;
  }
}

@media (max-width: 767px) {
  .hero-media {
    display: none;
  }
}

.hero-media-mobile {
  display: none;
  padding: 0 10px;
}

.hero-media-mobile img {
  width: 100%;
}

@media (max-width: 767px) {
  .hero-media-mobile {
    display: block;
    width: 320px;
    margin: auto;
  }
}

/* about us styles */
.about-us {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1100px) {
  .about-us {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .about-us {
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
}

.about-us-content {
  width: 300px;
  margin-right: 130px;
  margin-top: 110px;
}

@media (max-width: 1200px) {
  .about-us-content {
    margin-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .about-us-content {
    margin-top: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.about-us-content .section-header {
  border-bottom: 1px solid #7daa48;
  display: flex;
  align-items: flex-end;
}

.about-us-content .section-header h4 {
  font-size: 40px;
  color: #5a5e56;
  font-family: lato-regular;
  display: inline-block;
}

@media (max-width: 1100px) {
  .about-us-content .section-header h4 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .about-us-content .section-header h4 {
    font-size: 21px;
    padding-bottom: 5px;
  }
}

.about-us-content .section-header img {
  display: inline-block;
  position: relative;
  left: -40px;
}

@media (max-width: 767px) {
  .about-us-content .section-header img {
    width: 60px;
    height: 60px;
    left: -20px;
  }
}

.about-us-content p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  color: #585858;
  font-family: lato-regular;
}

@media (max-width: 767px) {
  .about-us-content p {
    font-size: 14px;
    line-height: 18px;
  }
}

.about-us-media-block {
  width: 745px;
  background-color: #eedd9a;
  overflow: hidden;
  padding-bottom: 180px;
  margin-top: -1px;
}

@media (max-width: 1100px) {
  .about-us-media-block {
    width: 430px;
    padding-top: 0px;
    padding-bottom: 110px;
  }
}

@media (max-width: 767px) {
  .about-us-media-block {
    width: 100%;
    padding-bottom: 110px;
    padding-bottom: 90px;
  }
}

.about-us-rows {
  display: flex;
}

.about-us-rows:not(:last-child) {
  margin-bottom: 35px;
}

@media (max-width: 767px) {
  .about-us-rows:not(:last-child) {
    margin-bottom: 15px;
  }
}

.about-us-column {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 210px;
  border: 2px solid #ecb003;
  background-color: #fff6da;
  box-shadow: 0px 5px 4.6px 0.4px rgba(2, 2, 2, 0.28);
  border-radius: 20px;
}

.about-us-column .column-wrap {
  transform: rotate(45deg);
  text-align: center;
}

.about-us-column .column-wrap img {
  margin-top: 10px;
}

@media (max-width: 1100px) {
  .about-us-column .column-wrap img {
    width: 45%;
    margin-top: 7px;
  }
}

@media (max-width: 767px) {
  .about-us-column .column-wrap img {
    width: 45%;
    margin-top: 7px;
  }
}

.about-us-column:not(:last-child) {
  margin-right: 30px;
}

@media (max-width: 767px) {
  .about-us-column:not(:last-child) {
    margin-right: 15px;
  }
}

@media (max-width: 1100px) {
  .about-us-column {
    width: 110px;
    height: 110px;
  }
}

@media (max-width: 767px) {
  .about-us-column {
    width: 100px;
    height: 100px;
  }
}

.about-us-column.blue {
  border: 2px solid #1f92b1;
  background-color: #d3f4d9;
}

.about-us-column.empty {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.about-us-column-description {
  font-size: 18px;
  color: #5e5d5d;
  font-family: lato-regular;
  max-width: 150px;
  text-align: center;
  margin-top: 17px;
}

.about-us-column-description.small {
  max-width: 130px;
}

@media (max-width: 1100px) {
  .about-us-column-description.mobile-small {
    max-width: 80px;
  }
}

@media (max-width: 1100px) {
  .about-us-column-description {
    font-size: 12px;
    margin-top: 26px;
  }
}

@media (max-width: 767px) {
  .about-us-column-description {
    font-size: 11px;
    margin-top: 16px;
  }
}

.about-us-rows-group {
  transform: rotate(-45deg);
  margin-top: -50px;
}

@media (max-width: 1100px) {
  .about-us-rows-group {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .about-us-rows-group {
    margin-top: 0;
    max-width: 320px;
    margin: auto;
  }
}

.about-us-pattern {
  display: none;
  width: 100%;
}

@media (max-width: 767px) {
  .about-us-pattern {
    display: block;
  }
}

.about-us-pattern svg {
  fill: #eedd9a;
}

.service-pattern {
  display: none;
  width: 100%;
}

@media (max-width: 767px) {
  .service-pattern {
    display: block;
  }
}

.service-pattern svg {
  fill: #eaf2a9;
}

.solutions-pattern {
  display: none;
  width: 100%;
}

@media (max-width: 767px) {
  .solutions-pattern {
    display: block;
  }
}

.solutions-pattern svg {
  fill: #b7e1dd;
}

.approach-pattern {
  display: none;
  width: 100%;
}

@media (max-width: 767px) {
  .approach-pattern {
    display: block;
  }
}

.approach-pattern svg {
  fill: #d3e6b3;
}

.customers-partners-pattern {
  display: none;
  width: 100%;
}

@media (max-width: 767px) {
  .customers-partners-pattern {
    display: block;
  }
}

.customers-partners-pattern svg {
  fill: #ddf3fb;
}

.founders-pattern {
  display: none;
  width: 100%;
}

@media (max-width: 767px) {
  .founders-pattern {
    display: block;
  }
}

.founders-pattern svg {
  fill: #d0e6b3;
}

.philosophy-pattern {
  display: none;
  width: 100%;
}

@media (max-width: 767px) {
  .philosophy-pattern {
    display: block;
  }
}

.philosophy-pattern svg {
  fill: #fffaad;
}

.get-in-touch-pattern {
  display: none;
  width: 100%;
}

@media (max-width: 767px) {
  .get-in-touch-pattern {
    display: block;
  }
}

.get-in-touch-pattern svg {
  fill: #e4e7ea;
}

/* services styles */
.services-us {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1100px) {
  .services-us {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .services-us {
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }
}

.services-us-content {
  width: 300px;
  margin-right: 130px;
  margin-top: 165px;
}

@media (max-width: 1200px) {
  .services-us-content {
    margin-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .services-us-content {
    margin-right: 0;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.services-us-content .section-header {
  border-bottom: 1px solid #7daa48;
  display: flex;
  align-items: flex-end;
}

.services-us-content .section-header h4 {
  font-size: 40px;
  color: #5a5e56;
  font-family: lato-regular;
  display: inline-block;
}

@media (max-width: 1100px) {
  .services-us-content .section-header h4 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .services-us-content .section-header h4 {
    font-size: 21px;
    padding-bottom: 5px;
  }
}

.services-us-content .section-header img {
  display: inline-block;
  position: relative;
  left: -40px;
}

@media (max-width: 767px) {
  .services-us-content .section-header img {
    width: 60px;
    height: 60px;
    left: -20px;
  }
}

.services-us-content p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  color: #585858;
  font-family: lato-regular;
}

@media (max-width: 767px) {
  .services-us-content p {
    font-size: 14px;
    line-height: 18px;
  }
}

.section-sub-header {
  font-size: 16px;
  color: #585858;
  font-family: lato-bold;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .section-sub-header {
    margin-top: 20px;
  }
}

.section-list li {
  font-size: 16px;
  font-family: lato-regular;
  color: #585858;
  margin-top: 6px;
}

@media (max-width: 767px) {
  .section-list li {
    font-size: 14px;
    margin-top: 3px;
  }
}

.services-us-media-block {
  width: 745px;
  background-color: #eaf2a9;
  overflow: hidden;
  padding-bottom: 45px;
  margin-top: -1px;
}

@media (max-width: 1100px) {
  .services-us-media-block {
    width: 430px;
  }
}

@media (max-width: 767px) {
  .services-us-media-block {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-top: 10px;
  }
}

.services-media-content {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .services-media-content + .services-media-content {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .services-media-content {
    margin-top: 0;
    flex-direction: row-reverse;
    max-width: 320px;
    margin: auto;
  }
}

.services-content-wrap {
  margin-left: 80px;
}

@media (max-width: 1100px) {
  .services-content-wrap {
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .services-content-wrap {
    margin-left: 15px;
  }
}

.services-content-wrap h4 {
  font-size: 25px;
  color: #7daa48;
  font-family: lato-regular;
}

@media (max-width: 1100px) {
  .services-content-wrap h4 {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .services-content-wrap h4 {
    font-size: 16px;
  }
}

.services-content-wrap p {
  max-width: 266px;
  margin-top: 5px;
  color: #5e5d5d;
  font-size: 20px;
  font-family: lato-regular;
  line-height: 24px;
}

@media (max-width: 1100px) {
  .services-content-wrap p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .services-content-wrap p {
    font-size: 13px;
    line-height: 16px;
  }
}

.services-content-image {
  margin-right: 120px;
}

.services-content-image.vlsi {
  margin-right: 100px;
}

@media (max-width: 1100px) {
  .services-content-image.vlsi {
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .services-content-image.vlsi {
    margin-right: 0;
  }
}

.services-content-image.gaming {
  margin-right: 140px;
}

@media (max-width: 1100px) {
  .services-content-image.gaming {
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .services-content-image.gaming {
    margin-right: 0;
  }
}

@media (max-width: 1100px) {
  .services-content-image {
    margin-right: 30px;
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .services-content-image {
    margin-right: 0;
    width: 100px;
  }
}

@media (max-width: 1100px) {
  .services-content-image img {
    width: 110px;
  }
}

@media (max-width: 767px) {
  .services-content-image img {
    width: 95%;
  }
}

/* solutions styles */
.solutions-us {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1100px) {
  .solutions-us {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .solutions-us {
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }
}

.solutions-us-content {
  width: 300px;
  margin-right: 130px;
  margin-top: 160px;
}

@media (max-width: 1200px) {
  .solutions-us-content {
    margin-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .solutions-us-content {
    margin-right: 0;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.solutions-us-content .section-header {
  border-bottom: 1px solid #7daa48;
  display: flex;
  align-items: flex-end;
}

.solutions-us-content .section-header h4 {
  font-size: 40px;
  color: #5a5e56;
  font-family: lato-regular;
  display: inline-block;
}

@media (max-width: 1100px) {
  .solutions-us-content .section-header h4 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .solutions-us-content .section-header h4 {
    font-size: 21px;
    padding-bottom: 5px;
  }
}

.solutions-us-content .section-header img {
  display: inline-block;
  position: relative;
  left: -40px;
}

@media (max-width: 767px) {
  .solutions-us-content .section-header img {
    width: 60px;
    height: 60px;
    left: -20px;
  }
}

.solutions-us-content p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  color: #585858;
  font-family: lato-regular;
}

@media (max-width: 767px) {
  .solutions-us-content p {
    font-size: 14px;
    line-height: 18px;
  }
}

.solutions-us-media-block {
  width: 745px;
  background-color: #b7e0dd;
  position: relative;
  overflow: hidden;
  font-size: 0;
  margin-top: -1px;
}

@media (max-width: 1100px) {
  .solutions-us-media-block {
    width: 430px;
  }
}

@media (max-width: 767px) {
  .solutions-us-media-block {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 1100px) {
  .solutions-us-media-block img {
    width: 430px;
  }
}

@media (max-width: 767px) {
  .solutions-us-media-block img {
    width: 320px;
  }
}

.solutions-point {
  text-align: center;
}

.solutions-point label {
  display: block;
  font-size: 20px;
  color: #5e5d5d;
  font-family: lato-regular;
  margin-top: 10px;
}

@media (max-width: 1100px) {
  .solutions-point label {
    width: 130px;
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .solutions-point label {
    font-size: 8px;
    width: 100%;
  }
}

@media (max-width: 1100px) {
  .solutions-point > img {
    width: 50px;
  }
}

.solutions-point.point1 {
  margin-left: 155px;
}

@media (max-width: 1100px) {
  .solutions-point.point1 {
    margin-left: 55px;
  }
}

@media (max-width: 767px) {
  .solutions-point.point1 {
    margin-left: 68px;
  }
}

.solutions-point.point2 {
  margin-right: 80px;
}

@media (max-width: 1100px) {
  .solutions-point.point2 {
    margin-right: 33px;
  }
}

@media (max-width: 767px) {
  .solutions-point.point2 {
    margin-right: 34px;
  }
}

.solutions-point.point3 {
  margin-left: 290px;
}

@media (max-width: 1100px) {
  .solutions-point.point3 {
    margin-left: 150px;
  }
}

@media (max-width: 767px) {
  .solutions-point.point3 {
    margin-left: 126px;
  }
}

.solutions-point.point4 {
  margin-left: 125px;
}

@media (max-width: 1100px) {
  .solutions-point.point4 {
    margin-left: 55px;
  }
}

@media (max-width: 767px) {
  .solutions-point.point4 {
    margin-left: 60px;
  }
}

.solutions-point.point5 {
  margin-right: 130px;
}

@media (max-width: 1100px) {
  .solutions-point.point5 {
    margin-right: 40px;
  }
}

@media (max-width: 767px) {
  .solutions-point.point5 {
    margin-right: 55px;
  }
}

.solutions-point.point6 {
  margin-left: 330px;
}

@media (max-width: 1100px) {
  .solutions-point.point6 {
    margin-left: 156px;
  }
}

@media (max-width: 767px) {
  .solutions-point.point6 {
    margin-left: 138px;
  }
}

.solutions-media-points-group {
  position: absolute;
  top: 134px;
  left: 0;
  right: 0;
}

@media (max-width: 1100px) {
  .solutions-media-points-group {
    top: 84px;
  }
}

@media (max-width: 767px) {
  .solutions-media-points-group {
    top: 60px;
    max-width: 320px;
    margin: auto;
  }
}

.solutions-point-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 90px;
}

@media (max-width: 1100px) {
  .solutions-point-row {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .solutions-point-row {
    margin-bottom: 20px;
  }
}

/* solutions styles */
.approach-us {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1100px) {
  .approach-us {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .approach-us {
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }
}

.approach-us-content {
  width: 300px;
  margin-right: 130px;
  margin-top: 150px;
}

@media (max-width: 1200px) {
  .approach-us-content {
    margin-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .approach-us-content {
    margin-right: 0;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.approach-us-content .section-header {
  border-bottom: 1px solid #7daa48;
  display: flex;
  align-items: flex-end;
}

.approach-us-content .section-header h4 {
  font-size: 40px;
  color: #5a5e56;
  font-family: lato-regular;
  display: inline-block;
}

@media (max-width: 1100px) {
  .approach-us-content .section-header h4 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .approach-us-content .section-header h4 {
    font-size: 21px;
    padding-bottom: 5px;
  }
}

.approach-us-content .section-header img {
  display: inline-block;
  position: relative;
  left: -40px;
}

@media (max-width: 767px) {
  .approach-us-content .section-header img {
    width: 60px;
    height: 60px;
    left: -20px;
  }
}

.approach-us-content p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  color: #585858;
  font-family: lato-regular;
}

@media (max-width: 767px) {
  .approach-us-content p {
    font-size: 14px;
    line-height: 18px;
  }
}

.approach-us-media-block {
  width: 745px;
  background-color: #d3e6b3;
  position: relative;
  overflow: hidden;
  margin-top: -1px;
}

@media (max-width: 1100px) {
  .approach-us-media-block {
    width: 430px;
  }
}

@media (max-width: 767px) {
  .approach-us-media-block {
    width: 100%;
  }
}

.approach-point-header {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.approach-point-header img {
  width: 41px;
  height: 28px;
  display: inline-block;
  vertical-align: middle;
}

.approach-point-header img.big {
  width: 45px;
  height: 37px;
}

.approach-point-header h4 {
  font-size: 24px;
  vertical-align: middle;
  color: #534d4d;
  font-family: lato-regular;
  display: inline-block;
  margin-left: 15px;
}

@media (max-width: 1100px) {
  .approach-point-header h4 {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .approach-point-header h4 {
    font-size: 17px;
  }
}

.approach-bridge {
  margin-left: 120px;
  margin-top: 5px;
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .approach-bridge {
    margin-left: 85px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.approach-bridge img {
  display: inline-block;
  vertical-align: middle;
  width: 2px;
}

.approach-bridge p {
  color: #534d4d;
  font-size: 18px;
  font-family: lato-regular;
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

@media (max-width: 1100px) {
  .approach-bridge p {
    max-width: 220px;
    font-size: 14px;
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .approach-bridge p {
    font-size: 12px;
    margin-left: 8px;
    max-width: 130px;
  }
}

.approach-group {
  margin-left: 235px;
  padding-top: 45px;
  padding-bottom: 20px;
}

@media (max-width: 1100px) {
  .approach-group {
    margin-left: 35px;
  }
}

@media (max-width: 767px) {
  .approach-group {
    margin-left: 28px;
    width: 270px;
    margin: auto;
    padding-top: 15px;
  }
}

/* customers and partners styles */
.customers-partners {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1100px) {
  .customers-partners {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .customers-partners {
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }
}

.customers-partners-content {
  width: 300px;
  margin-right: 130px;
  margin-top: 185px;
}

@media (max-width: 1200px) {
  .customers-partners-content {
    margin-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .customers-partners-content {
    margin-right: 0;
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.customers-partners-content .section-header {
  border-bottom: 1px solid #7daa48;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.customers-partners-content .section-header h4 {
  font-size: 40px;
  color: #5a5e56;
  font-family: lato-regular;
  display: inline-block;
  line-height: 40px;
  padding-bottom: 10px;
}

@media (max-width: 1100px) {
  .customers-partners-content .section-header h4 {
    font-size: 30px;
    line-height: 32px;
  }
}

@media (max-width: 767px) {
  .customers-partners-content .section-header h4 {
    font-size: 21px;
    padding-bottom: 5px;
    line-height: 24px;
  }
}

.customers-partners-content .section-header img {
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: rotate(31deg);
}

@media (max-width: 767px) {
  .customers-partners-content .section-header img {
    width: 60px;
    height: 60px;
    transform: rotate(6deg);
  }
}

.customers-partners-content p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  color: #585858;
  font-family: lato-regular;
}

@media (max-width: 767px) {
  .customers-partners-content p {
    font-size: 14px;
    line-height: 18px;
  }
}

.customers-partners-media-block {
  width: 745px;
  background-color: #ddf3fb;
  overflow: hidden;
  padding-bottom: 140px;
  margin-top: -1px;
}

@media (max-width: 1100px) {
  .customers-partners-media-block {
    width: 430px;
    padding-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .customers-partners-media-block {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-top: 10px;
  }
}

.compaines-block {
  margin-left: 50px;
  display: inline-block;
  vertical-align: middle;
  margin-top: 80px;
}

@media (max-width: 1100px) {
  .compaines-block {
    margin-left: 0;
    display: block;
    text-align: center;
  }
  .compaines-block > img {
    width: 144px;
  }
}

@media (max-width: 767px) {
  .compaines-block {
    margin-left: 0;
    margin-top: 45px;
    display: block;
  }
  .compaines-block > img {
    width: 100px;
  }
}

.compaines-group {
  padding-top: 80px;
}

@media (max-width: 1100px) {
  .compaines-group {
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .compaines-group {
    padding-top: 0;
    text-align: center;
  }
}

/* about us styles */
.founders {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1100px) {
  .founders {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .founders {
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }
}

.founders-content {
  width: 300px;
  margin-right: 130px;
  margin-top: 165px;
}

@media (max-width: 1200px) {
  .founders-content {
    margin-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .founders-content {
    margin-right: 0;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.founders-content .section-header {
  border-bottom: 1px solid #7daa48;
  display: flex;
  align-items: flex-end;
}

.founders-content .section-header h4 {
  font-size: 40px;
  color: #5a5e56;
  font-family: lato-regular;
  display: inline-block;
}

@media (max-width: 1100px) {
  .founders-content .section-header h4 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .founders-content .section-header h4 {
    font-size: 21px;
    padding-bottom: 5px;
  }
}

.founders-content .section-header img {
  display: inline-block;
  position: relative;
  left: -40px;
}

@media (max-width: 767px) {
  .founders-content .section-header img {
    width: 60px;
    height: 60px;
    left: -20px;
  }
}

.founders-content p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  color: #585858;
  font-family: lato-regular;
}

@media (max-width: 767px) {
  .founders-content p {
    font-size: 14px;
    line-height: 18px;
  }
}

.founders-media-block {
  width: 745px;
  background-color: #d0e6b3;
  overflow: hidden;
  padding-bottom: 60px;
  margin-top: -1px;
}

@media (max-width: 1100px) {
  .founders-media-block {
    width: 430px;
  }
}

@media (max-width: 767px) {
  .founders-media-block {
    width: 100%;
    padding-bottom: 30px;
  }
}

.founder-name {
  font-family: lato-bold;
  color: #585858;
  font-size: 24px;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .founder-name {
    font-size: 15px;
  }
}

.founder-designation {
  font-size: 16px;
  color: #585858;
  font-family: lato-regular;
  margin-top: 5px;
}

@media (max-width: 767px) {
  .founder-designation {
    font-size: 13px;
  }
}

.founder-category {
  font-size: 14px;
  color: #585858;
  font-family: lato-regular;
  margin-top: 5px;
}

@media (max-width: 767px) {
  .founder-category {
    font-size: 12px;
  }
}

.founder-description {
  font-size: 16px;
  color: #585858;
  font-family: lato-regular;
  line-height: 24px;
  margin-top: 15px;
  margin-right: 60px;
}

@media (max-width: 767px) {
  .founder-description {
    display: none;
  }
}

.about-founder-group {
  display: flex;
}

@media (max-width: 1100px) {
  .about-founder-group {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .about-founder-group {
    flex-direction: column-reverse;
  }
}

.about-founder-group + .about-founder-group {
  margin-top: 60px;
}

.founders-group {
  padding-top: 70px;
  padding-left: 50px;
}

@media (max-width: 767px) {
  .founders-group {
    padding-left: 0;
    max-width: 320px;
    margin: auto;
    padding-top: 30px;
  }
}

.founder-content {
  padding-top: 160px;
  padding-left: 15px;
}

@media (max-width: 1100px) {
  .founder-content {
    padding-top: 20px;
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .founder-content {
    padding-top: 0;
    padding-left: 30px;
  }
}

.founder-description-mobile {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  color: #f0efef;
  font-family: lato-regular;
  font-size: 14px;
  line-height: 18px;
  padding: 55px 20px 40px 20px;
}

.founder-description-mobile-close {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
}

.founder-image {
  position: relative;
  font-size: 0;
}

@media (max-width: 767px) {
  .founder-image.show .founder-description-mobile {
    display: block;
  }
}

@media (max-width: 767px) {
  .founder-image.show .founder-description-mobile-close {
    display: block;
  }
}

@media (max-width: 767px) {
  .founder-image {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .founder-image > img {
    width: 100%;
  }
}

.details {
  font-family: lato-regular;
  font-size: 12px;
  color: #2f2f2f;
  float: right;
  margin-right: 10px;
  display: none;
}

@media (max-width: 767px) {
  .details {
    display: inline-block;
  }
}

/* philosophy styles */
.philosophy {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1100px) {
  .philosophy {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .philosophy {
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }
}

.philosophy-content {
  width: 300px;
  margin-right: 130px;
  margin-top: 150px;
}

@media (max-width: 1200px) {
  .philosophy-content {
    margin-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .philosophy-content {
    margin-right: 0;
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.philosophy-content .section-header {
  border-bottom: 1px solid #7daa48;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.philosophy-content .section-header h4 {
  font-size: 40px;
  color: #5a5e56;
  font-family: lato-regular;
  display: inline-block;
}

@media (max-width: 1100px) {
  .philosophy-content .section-header h4 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .philosophy-content .section-header h4 {
    font-size: 21px;
    padding-bottom: 5px;
  }
}

.philosophy-content .section-header img {
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 767px) {
  .philosophy-content .section-header img {
    width: 60px;
    height: 60px;
    right: 90px;
    transform: rotate(21deg);
  }
}

.philosophy-content p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  color: #585858;
  font-family: lato-regular;
}

@media (max-width: 767px) {
  .philosophy-content p {
    font-size: 14px;
    line-height: 18px;
  }
}

.philosophy-media-block {
  width: 745px;
  background-color: #fffaad;
  position: relative;
  overflow: hidden;
  margin-top: -1px;
  text-align: center;
}

@media (max-width: 1100px) {
  .philosophy-media-block {
    width: 430px;
  }
  .philosophy-media-block > img {
    width: 430px;
  }
}

@media (max-width: 767px) {
  .philosophy-media-block {
    text-align: center;
    width: 100%;
  }
  .philosophy-media-block > img {
    width: 320px;
  }
}

.philosophy-group {
  margin-top: 87px;
  margin-left: 69px;
  max-width: 230px;
}

@media (max-width: 1100px) {
  .philosophy-group {
    margin-left: 38px;
    max-width: 210px;
    margin-top: 57px;
  }
}

@media (max-width: 767px) {
  .philosophy-group {
    max-width: 150px;
    margin-left: 28px;
    margin-top: 27px;
  }
}

.philosophy-group h4 {
  text-align: center;
  font-size: 35px;
  color: #585858;
  font-family: lato-regular;
}

@media (max-width: 1100px) {
  .philosophy-group h4 {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .philosophy-group h4 {
    font-size: 18px;
  }
}

.philosophy-group p {
  font-family: lato-regular;
  color: #585858;
  font-size: 16px;
  line-height: 22px;
  margin-top: 8px;
  text-align: center;
}

@media (max-width: 1100px) {
  .philosophy-group p {
    font-size: 13px;
    line-height: 18px;
  }
}

@media (max-width: 767px) {
  .philosophy-group p {
    font-size: 12px;
    line-height: 13px;
    margin-top: 0;
  }
}

.philosophy-group:nth-child(1) {
  margin-top: 120px;
}

@media (max-width: 1100px) {
  .philosophy-group:nth-child(1) {
    margin-top: 85px;
    margin-left: 30px;
  }
}

@media (max-width: 767px) {
  .philosophy-group:nth-child(1) {
    margin-top: 55px;
  }
}

.philosophy-group:nth-child(2) {
  margin-left: 255px;
  margin-top: 50px;
}

@media (max-width: 1100px) {
  .philosophy-group:nth-child(2) {
    margin-left: 180px;
  }
}

@media (max-width: 767px) {
  .philosophy-group:nth-child(2) {
    margin-left: 140px;
    margin-top: 40px;
  }
}

.philosophy-group:nth-child(3) {
  margin-left: 93px;
}

@media (max-width: 1100px) {
  .philosophy-group:nth-child(3) {
    margin-left: 40px;
  }
}

@media (max-width: 767px) {
  .philosophy-group:nth-child(3) {
    margin-left: 40px;
    margin-top: 40px;
  }
}

.philosophy-group-wrap {
  position: absolute;
  top: 0;
  left: 85px;
}

@media (max-width: 1100px) {
  .philosophy-group-wrap {
    left: 0;
  }
}

@media (max-width: 767px) {
  .philosophy-group-wrap {
    right: 0;
    width: 320px;
    margin: auto;
  }
}

/* philosophy styles */
.get-in-touch {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1100px) {
  .get-in-touch {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .get-in-touch {
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }
}

.get-in-touch-content {
  width: 300px;
  margin-right: 130px;
  margin-top: 200px;
}

@media (max-width: 1200px) {
  .get-in-touch-content {
    margin-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .get-in-touch-content {
    margin-right: 0;
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.get-in-touch-content .section-header {
  border-bottom: 1px solid #7daa48;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.get-in-touch-content .section-header h4 {
  font-size: 40px;
  color: #5a5e56;
  font-family: lato-regular;
  display: inline-block;
}

@media (max-width: 1100px) {
  .get-in-touch-content .section-header h4 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .get-in-touch-content .section-header h4 {
    font-size: 21px;
    padding-bottom: 5px;
  }
}

.get-in-touch-content .section-header img {
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 767px) {
  .get-in-touch-content .section-header img {
    width: 60px;
    height: 60px;
    right: 90px;
    transform: rotate(21deg);
  }
}

.get-in-touch-content p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  color: #585858;
  font-family: lato-regular;
}

@media (max-width: 767px) {
  .get-in-touch-content p {
    font-size: 14px;
    line-height: 18px;
  }
}

.get-in-touch-media-block {
  width: 745px;
  background-color: #e4e7ea;
  position: relative;
  overflow: hidden;
  margin-top: -1px;
}

@media (max-width: 1100px) {
  .get-in-touch-media-block {
    width: 430px;
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .get-in-touch-media-block {
    width: 100%;
    padding-bottom: 50px;
  }
}

.get-in-touch-map-wrap {
  display: flex;
}

@media (max-width: 1100px) {
  .get-in-touch-map-wrap {
    flex-direction: column;
    width: 430px;
    margin: auto;
  }
}

@media (max-width: 767px) {
  .get-in-touch-map-wrap {
    flex-direction: column;
    width: 320px;
    margin: auto;
  }
}

.get-in-touch-address {
  border-right: 1px solid #797a7c;
  width: 440px;
  padding-left: 50px;
}

@media (max-width: 1100px) {
  .get-in-touch-address {
    padding-left: 25px;
    border-right: none;
    border-bottom: 1px solid #929191;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .get-in-touch-address {
    padding-left: 25px;
    padding-right: 10px;
    border-right: none;
    border-bottom: 1px solid #929191;
    width: 100%;
  }
}

.get-in-touch-map {
  border-left: 1px solid #6b6d6e;
}

@media (max-width: 1100px) {
  .get-in-touch-map {
    display: none;
  }
}

.get-in-touch-map-mobile {
  display: none;
  border-bottom: 1px solid #929191;
}

@media (max-width: 1100px) {
  .get-in-touch-map-mobile {
    display: block;
    text-align: center;
  }
}

.get-in-address-block {
  display: flex;
  padding-top: 20px;
}

@media (max-width: 767px) {
  .get-in-address-icon img {
    width: 13px;
    height: 20px;
  }
}

.get-in-address-content {
  padding-left: 15px;
}

@media (max-width: 767px) {
  .get-in-address-content {
    padding-left: 10px;
  }
}

.get-in-address-content .center {
  font-size: 22px;
  font-family: lato-bold;
  color: #5e5d5d;
}

@media (max-width: 1100px) {
  .get-in-address-content .center {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .get-in-address-content .center {
    font-size: 17px;
  }
}

.get-in-address-content .center-name {
  font-size: 20px;
  color: #5e5d5d;
  font-family: lato-regular;
  margin-top: 3px;
  margin-bottom: 3px;
}

@media (max-width: 767px) {
  .get-in-address-content .center-name {
    font-size: 17px;
  }
}

.get-in-address-content .center-address {
  font-size: 16px;
  color: #5e5d5d;
  line-height: 24px;
}

@media (max-width: 767px) {
  .get-in-address-content .center-address {
    font-size: 14px;
    line-height: 16px;
  }
}

.get-in-touch-group {
  padding-top: 110px;
}

@media (max-width: 1100px) {
  .get-in-touch-group {
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .get-in-touch-group {
    padding-top: 10px;
  }
}

.get-in-touch-group + .get-in-touch-group {
  padding-top: 100px;
}

@media (max-width: 1100px) {
  .get-in-touch-group + .get-in-touch-group {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .get-in-touch-group + .get-in-touch-group {
    padding-top: 25px;
    padding-bottom: 20px;
  }
}

/* footer */
.footer-block {
  background-color: #617c40;
}

@media (max-width: 1300px) {
  .footer-block {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .footer-block {
    padding: 0 20px;
  }
}

.footer-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
}

@media (max-width: 767px) {
  .footer-group {
    height: 30px;
  }
}

.social-icons a {
  display: inline-block;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .social-icons a > img {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 767px) {
  .social-icons a {
    margin-top: 2px;
  }
  .social-icons a > img {
    width: 25px;
    height: 25px;
  }
}

.social-icons a + a {
  margin-left: 25px;
}

@media (max-width: 767px) {
  .social-icons a + a {
    margin-left: 10px;
  }
}

.copy-right {
  font-size: 16px;
  color: #f4f1e9;
  font-family: lato-regular;
  padding-left: 80px;
}

@media (max-width: 1280px) {
  .copy-right {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .copy-right {
    display: none;
  }
}

.mobile-copy-right {
  display: none;
  font-size: 10px;
  color: #f4f1e9;
  font-family: lato-regular;
}

@media (max-width: 767px) {
  .mobile-copy-right {
    display: block;
  }
}

.it-wrap .services-content-wrap h4 {
  color: #227d96;
  max-width: 270px;
}

.it-wrap .about-us-media-block {
  background-color: #d0e6b3;
  padding-top: 30px;
  padding-bottom: 200px;
}

@media (max-width: 1100px) {
  .it-wrap .about-us-media-block {
    padding-top: 0px;
    padding-bottom: 110px;
  }
}

@media (max-width: 767px) {
  .it-wrap .about-us-media-block {
    padding-top: 10px;
    padding-bottom: 110px;
  }
}

@media (max-width: 1100px) {
  .it-wrap .about-us-rows-group {
    margin-top: -20px;
  }
}

.it-wrap .about-us-column {
  background-color: #e2ecd6;
}

.it-wrap .about-us-column.red {
  border: 2px solid #df3c4d;
}

.it-wrap .about-us-column.empty {
  background-color: transparent;
}

.it-wrap .services-us-media-block {
  background-color: #fffaad;
}

.it-wrap .section-header img {
  display: none;
}

@media (max-width: 767px) {
  .it-wrap .hero-media-mobile {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .it-wrap .hero-media-mobile img {
    display: block;
    margin-bottom: -1px;
  }
}

@media (max-width: 767px) {
  .it-wrap .hero-block {
    position: relative;
  }
}

@media (max-width: 767px) {
  .it-wrap .hero-content {
    position: absolute;
    top: 74%;
    padding: 0 35px;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .it-wrap .about-us {
    background-color: #d0e6b3;
    margin-top: 0;
  }
}

.it-wrap .about-us-content {
  margin-top: 215px;
}

@media (max-width: 767px) {
  .it-wrap .about-us-content {
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .it-wrap .services-us {
    background-color: #fffaad;
  }
}

.it-wrap .services-us-content {
  margin-top: 240px;
}

@media (max-width: 767px) {
  .it-wrap .services-us-content {
    margin-top: 40px;
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) {
  .it-wrap .approach-us {
    background-color: #d3e6b3;
  }
}

.it-wrap .approach-us-content {
  margin-top: 230px;
}

@media (max-width: 767px) {
  .it-wrap .approach-us-content {
    margin-top: 40px;
    margin-bottom: 25px;
  }
}

.it-wrap .customers-partners-content {
  margin-top: 235px;
}

@media (max-width: 767px) {
  .it-wrap .customers-partners-content {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .it-wrap .customers-partners {
    background-color: #ddf3fb;
  }
}

@media (max-width: 767px) {
  .it-wrap .founders {
    background-color: #d0e6b3;
  }
}

.it-wrap .founders-content {
  margin-top: 240px;
}

@media (max-width: 767px) {
  .it-wrap .founders-content {
    margin-top: 40px;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .it-wrap .philosophy {
    background-color: #fffaad;
  }
}

.it-wrap .philosophy-content {
  margin-top: 220px;
}

@media (max-width: 767px) {
  .it-wrap .philosophy-content {
    margin-top: 40px;
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) {
  .it-wrap .get-in-touch {
    background-color: #e4e7ea;
  }
}

.it-wrap .get-in-touch-content {
  margin-top: 230px;
}

@media (max-width: 767px) {
  .it-wrap .get-in-touch-content {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .it-wrap .get-in-touch-address {
    padding-right: 10px;
  }
}
