/* about us styles */
.about-us {
    display: flex;
    justify-content: flex-end;
    @include layout(1100) {
        justify-content: center;
    }
    @include layout(767) {
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
}
.about-us-content {
    width: 300px;
    margin-right: 130px;
    margin-top: 110px;
    @include layout(1200) {
        margin-right: 30px;
        padding-left: 30px;
    }
    @include layout(767) {
        margin-top: 0;
        margin-right: 0;
        padding-left: 20px;
        padding-right: 20px;
    }
    .section-header {
        border-bottom: 1px solid #7daa48;
        display: flex;
        align-items: flex-end;
        h4 {
            font-size: 40px;
            color: #5a5e56;
            font-family: $reg;
            display: inline-block;
            @include layout(1100) {
                font-size: 30px;
            }
            @include layout(767) {
                font-size: 21px;
                padding-bottom: 5px;
            }
        }
        img {
            display: inline-block;
            position: relative;
            left: -40px;
            @include layout(767) {
                width: 60px;
                height: 60px;
                left: -20px;
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        color: #585858;
        font-family: $reg;
        @include layout(767) {
            font-size: 14px;
            line-height: 18px;
        }
    }
}
.about-us-media-block {
    width: 745px;
    background-color: #eedd9a;
    overflow: hidden;
    padding-bottom: 180px;
    margin-top: -1px;
    @include layout(1100) {
        width: 430px;
        padding-top: 0px;
        padding-bottom: 110px; 
    }
    @include layout(767) {
        width: 100%;
        padding-bottom: 110px;
        padding-bottom: 90px;
    }
}
.about-us-rows {
    display: flex;
    &:not(:last-child) {
        margin-bottom: 35px;
        @include layout(767) {
            margin-bottom: 15px;
        }
    }
}
.about-us-column {
    .column-wrap {
        transform: rotate(45deg);
        text-align: center;
        img {
            margin-top: 10px;
            @include layout(1100) {
                width: 45%;
                margin-top: 7px;
            }
            @include layout(767) {
                width: 45%;
                margin-top: 7px;
            }
        }
    }
    &:not(:last-child) {
        margin-right: 30px;
        @include layout(767) {
            margin-right: 15px;
        }
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 210px;
    border: 2px solid #ecb003;
    background-color: #fff6da;
    box-shadow: 0px 5px 4.6px 0.4px rgba(2, 2, 2, 0.28); 
    border-radius: 20px;
    @include layout(1100) {
        width: 110px;
        height: 110px;
    }
    @include layout(767) {
        width: 100px;
        height: 100px;
    }
    &.blue {
        border: 2px solid #1f92b1;
        background-color: #d3f4d9;
    }
    &.empty {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
}
.about-us-column-description {
    font-size: 18px;
    color: #5e5d5d;
    font-family: $reg;
    max-width: 150px;
    text-align: center;
    margin-top: 17px;
    &.small {
        max-width: 130px;
    }
    &.mobile-small {
        @include layout(1100) {
            max-width: 80px;
        }
    }
    @include layout(1100) {
        font-size: 12px;
        margin-top: 26px;
    }
    @include layout(767) {
        font-size: 11px;
        margin-top: 16px;
    }
}
.about-us-rows-group {
    transform: rotate(-45deg);
    margin-top: -50px;
    @include layout(1100) {
        margin-top: 0;
    }
    @include layout(767) {
        margin-top: 0;
        max-width: 320px;
        margin: auto;
    }
}
.about-us-pattern {
    display: none;
    @include layout(767) {
        display: block;
    }
    width: 100%;
    svg {
        fill: #eedd9a;
    }
}
.service-pattern {
    display: none;
    @include layout(767) {
        display: block;
    }
    width: 100%;
    svg {
        fill:#eaf2a9;
    }
}
.solutions-pattern {
    display: none;
    @include layout(767) {
        display: block;
    }
    width: 100%;
    svg {
        fill:#b7e1dd;
    }
}
.approach-pattern {
    display: none;
    @include layout(767) {
        display: block;
    }
    width: 100%;
    svg {
        fill:#d3e6b3;
    }
}
.customers-partners-pattern {
    display: none;
    @include layout(767) {
        display: block;
    }
    width: 100%;
    svg {
        fill:#ddf3fb;
    }
}
.founders-pattern {
    display: none;
    @include layout(767) {
        display: block;
    }
    width: 100%;
    svg {
        fill:#d0e6b3;
    }
}
.philosophy-pattern {
    display: none;
    @include layout(767) {
        display: block;
    }
    width: 100%;
    svg {
        fill:#fffaad;
    }
}
.get-in-touch-pattern {
    display: none;
    @include layout(767) {
        display: block;
    }
    width: 100%;
    svg {
        fill: #e4e7ea;
    }
}