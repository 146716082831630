/* philosophy styles */
.get-in-touch {
    display: flex;
    justify-content: flex-end;
    @include layout(1100) {
        justify-content: center;
    }
    @include layout(767) {
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
    }
}
.get-in-touch-content {
    width: 300px;
    margin-right: 130px;
    margin-top: 200px;
    @include layout(1200) {
        margin-right: 30px;
        padding-left: 30px;
    }
    @include layout(767) {
        margin-right: 0;
        margin-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .section-header {
        border-bottom: 1px solid #7daa48;
        display: flex;
        align-items: flex-end;
        position: relative;
        h4 {
            font-size: 40px;
            color: #5a5e56;
            font-family: $reg;
            display: inline-block;
            @include layout(1100) {
                font-size: 30px;
            }
            @include layout(767) {
                font-size: 21px;
                padding-bottom: 5px;
            }
        }
        img {
            display: inline-block;
            position: absolute;
            right: 0;
            bottom: 0;
            @include layout(767) {
                width: 60px;
                height: 60px;
                right: 90px;
                transform: rotate(21deg);
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        color: #585858;
        font-family: $reg;
        @include layout(767) {
            font-size: 14px;
            line-height: 18px;
        }
    }
}
.get-in-touch-media-block {
    width: 745px;
    background-color: #e4e7ea;
    position: relative;
    overflow: hidden;
    margin-top: -1px;
    @include layout(1100) {
        width: 430px;
        padding-bottom: 20px; 
    }
    @include layout(767) {
        width: 100%;
        padding-bottom: 50px;
    }
}
.get-in-touch-map-wrap {
    display: flex;
    @include layout(1100) {
        flex-direction: column;
        width: 430px;
        margin: auto;
    }
    @include layout(767) {
        flex-direction: column;
        width: 320px;
        margin: auto;
    }
}
.get-in-touch-address {
    border-right: 1px solid #797a7c;
    width: 440px;
    padding-left: 50px;
    @include layout(1100) {
        padding-left: 25px;
        border-right: none;
        border-bottom: 1px solid #929191;
        width: 100%;
    }
    @include layout(767) {
        padding-left: 25px;
        padding-right: 10px;
        border-right: none;
        border-bottom: 1px solid #929191;
        width: 100%;
    }
}
.get-in-touch-map {
    border-left: 1px solid #6b6d6e;
    @include layout(1100) {
        display: none;
    }
}
.get-in-touch-map-mobile {
    display: none;
    border-bottom: 1px solid #929191;
    @include layout(1100) {
        display: block;
        text-align: center;
    }
}
.get-in-address-block {
    display: flex;
    padding-top: 20px;
}
.get-in-address-icon {
    img {
        @include layout(767) {
            width: 13px;
            height: 20px;
        }
    }
}
.get-in-address-content {
    padding-left: 15px;
    @include layout(767) {
        padding-left: 10px;
    }
    .center {
        font-size: 22px;
        font-family: $bold;
        color: #5e5d5d;
        @include layout(1100) {
            font-size: 20px;
        }
        @include layout(767) {
            font-size: 17px;
        }
    }
    .center-name {
        font-size: 20px;
        color: #5e5d5d;
        font-family: $reg;
        margin-top: 3px;
        margin-bottom: 3px;
        @include layout(767) {
            font-size: 17px;
        }
    }
    .center-address {
        font-size: 16px;
        color: #5e5d5d;
        line-height: 24px;
        @include layout(767) {
            font-size: 14px;
            line-height: 16px;
        }
    }
}
.get-in-touch-group {
    padding-top: 110px;
    @include layout(1100) {
        padding-top: 30px;
    }
    @include layout(767) {
        padding-top: 10px;
    }
    &+ .get-in-touch-group {
        padding-top: 100px;
        @include layout(1100) {
            padding-top: 50px;
            padding-bottom: 30px;
        }
        @include layout(767) {
            padding-top: 25px;
            padding-bottom: 20px;
        }
    }
}