/* about us styles */
.founders {
    display: flex;
    justify-content: flex-end;
    @include layout(1100) {
        justify-content: center;
    }
    @include layout(767) {
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
    }
}
.founders-content {
    width: 300px;
    margin-right: 130px;
    margin-top: 165px;
    @include layout(1200) {
        margin-right: 30px;
        padding-left: 30px;
    }
    @include layout(767) {
        margin-right: 0;
        margin-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .section-header {
        border-bottom: 1px solid #7daa48;
        display: flex;
        align-items: flex-end;
        h4 {
            font-size: 40px;
            color: #5a5e56;
            font-family: $reg;
            display: inline-block;
            @include layout(1100) {
                font-size: 30px;
            }
            @include layout(767) {
                font-size: 21px;
                padding-bottom: 5px;
            }
        }
        img {
            display: inline-block;
            position: relative;
            left: -40px;
            @include layout(767) {
                width: 60px;
                height: 60px;
                left: -20px;
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        color: #585858;
        font-family: $reg;
        @include layout(767) {
            font-size: 14px;
            line-height: 18px;
        }
    }
}
.founders-media-block {
    width: 745px;
    background-color: #d0e6b3;
    overflow: hidden;
    padding-bottom: 60px;
    margin-top: -1px;
    @include layout(1100) {
        width: 430px;  
    }
    @include layout(767) {
        width: 100%;
        padding-bottom: 30px;
    }
}
.founder-name {
    font-family: $bold;
    color: #585858;
    font-size: 24px;
    text-transform: uppercase;
    @include layout(767) {
        font-size: 15px;
    }
}
.founder-designation {
    font-size: 16px;
    color: #585858;
    font-family: $reg;
    margin-top: 5px;
    @include layout(767) {
        font-size: 13px;
    }
}
.founder-category {
    font-size: 14px;
    color: #585858;
    font-family: $reg;
    margin-top: 5px;
    @include layout(767) {
        font-size: 12px;
    }
}
.founder-description {
    font-size: 16px;
    color: #585858;
    font-family: $reg;
    line-height: 24px;
    margin-top: 15px;
    margin-right: 60px;
    @include layout(767) {
        display: none;
    }
}
.about-founder-group {
    display: flex;
    @include layout(1100) {
        flex-direction: column;
    }
    @include layout(767) {
        flex-direction: column-reverse;
    }
    &+ .about-founder-group {
        margin-top: 60px;
    }
}
.founders-group {
    padding-top: 70px;
    padding-left: 50px;
    @include layout(767) {
        padding-left: 0;
        max-width: 320px;
        margin: auto;
        padding-top: 30px;
    }
}
.founder-content {
    padding-top: 160px;
    padding-left: 15px;
    @include layout(1100) {
        padding-top: 20px;
        padding-left: 0;
    }
    @include layout(767) {
        padding-top: 0;
        padding-left: 30px;
    }
}
.founder-description-mobile {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.85);
    color: #f0efef;
    font-family: $reg;
    font-size: 14px;
    line-height: 18px;
    padding: 55px 20px 40px 20px;
}
.founder-description-mobile-close {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
}
.founder-image {
    &.show {
        .founder-description-mobile {
            @include layout(767) {
                display: block;
            }
        }
        .founder-description-mobile-close {
            @include layout(767) {
                display: block;
            }
        }
    }
    position: relative;
    font-size: 0;
    @include layout(767) {
        margin-top: 10px;
    }
    & > img {
        @include layout(767) {
            width: 100%;
        }
    }
}
.details {
    font-family: $reg;
    font-size: 12px;
    color: #2f2f2f;
    float: right;
    margin-right: 10px;
    display: none;
    @include layout(767) {
        display: inline-block;
    }
}