@import "variables";

/* hero styles */
.hero-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    @include layout(1100) {
        justify-content: center;
    }
    @include layout(767) {
        justify-content: flex-end;
        flex-direction: column-reverse;
        align-items: center;
    }
}
.hero-content {
    margin-top: 165px;
    padding-left: 78px;
    @include layout(1100) {
        padding-right: 40px;
        padding-left: 30px;
        width: 330px;
        margin-top: 95px;
    }
    @include layout(767) {
        margin-top: 25px;
        width: auto;
    }
    h4 {
        font-size: 40px;
        color: #57595f;
        font-family: $rale-sm;
        line-height: 44px;
        border-bottom: 2px solid #7daa48;
        padding-bottom: 8px;
        @include layout(1100) {
            font-size: 30px;
        }
        @include layout(767) {
            font-size: 22px;
            line-height: 26px;
        }
        @include layout(480) {
            padding-bottom: 3px;
        }
    }
    p {
        font-size: 36px;
        color: #7daa48;
        font-family: $rale-m;
        margin-top: 6px;
        @include layout(767) {
            font-size: 18px;
            line-height: 18px;
        }
    }
}
.hero-media {
    img {
        display: block;
        width: 745px;
        @include layout(1100) {
            width: 430px;
        }
    }
    @include layout(767) {
        display: none;
    }
}
.hero-media-mobile {
    display: none;
    padding: 0 10px;
    img {
        width: 100%;
    }
    @include layout(767) {
        display: block;
        width: 320px;
        margin: auto;
    }
}