/* philosophy styles */
.philosophy {
    display: flex;
    justify-content: flex-end;
    @include layout(1100) {
        justify-content: center;
    }
    @include layout(767) {
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
    }
}
.philosophy-content {
    width: 300px;
    margin-right: 130px;
    margin-top: 150px;
    @include layout(1200) {
        margin-right: 30px;
        padding-left: 30px;
    }
    @include layout(767) {
        margin-right: 0;
        margin-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .section-header {
        border-bottom: 1px solid #7daa48;
        display: flex;
        align-items: flex-end;
        position: relative;
        h4 {
            font-size: 40px;
            color: #5a5e56;
            font-family: $reg;
            display: inline-block;
            @include layout(1100) {
                font-size: 30px;
            }
            @include layout(767) {
                font-size: 21px;
                padding-bottom: 5px;
            }
        }
        img {
            display: inline-block;
            position: absolute;
            right: 0;
            bottom: 0;
            @include layout(767) {
                width: 60px;
                height: 60px;
                right: 90px;
                transform: rotate(21deg);
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        color: #585858;
        font-family: $reg;
        @include layout(767) {
            font-size: 14px;
            line-height: 18px;
        }
    }
}
.philosophy-media-block {
    width: 745px;
    background-color: #fffaad;
    position: relative;
    overflow: hidden;
    margin-top: -1px;
    text-align: center;
    @include layout(1100) {
        width: 430px;
        & > img {
            width: 430px;
        }
    }
    @include layout(767) {
        & > img {
            width: 320px;
        }
        text-align: center;
        width: 100%;
    }
}
.philosophy-group {
    margin-top: 87px;
    margin-left: 69px;
    max-width: 230px;
    @include layout(1100) {
        margin-left: 38px;
        max-width: 210px;
        margin-top: 57px;
    }
    @include layout(767) {
        max-width: 150px;
        margin-left: 28px;
        margin-top: 27px;
    }
    h4 {
        text-align: center;
        font-size: 35px;
        color: #585858;
        font-family: $reg;
        @include layout(1100) {
            font-size: 28px;
        }
        @include layout(767) {
            font-size: 18px;
        }
    }
    p {
        font-family: $reg;
        color: #585858;
        font-size: 16px;
        line-height: 22px;
        margin-top: 8px;
        text-align: center;
        @include layout(1100) {
            font-size: 13px;
            line-height: 18px;
        }
        @include layout(767) {
            font-size: 12px;
            line-height: 13px;
            margin-top: 0;
        }
    }
    &:nth-child(1) {
        margin-top: 120px;
        @include layout(1100) {
            margin-top: 85px;
            margin-left: 30px;
        }
        @include layout(767) {
            margin-top: 55px;
        }
    }
    &:nth-child(2) {
        margin-left: 255px;
        margin-top: 50px;
        @include layout(1100) {
            margin-left: 180px;
        }
        @include layout(767) {
            margin-left: 140px;
            margin-top: 40px;
        }
    }
    &:nth-child(3) {
        margin-left: 93px;
        @include layout(1100) {
            margin-left: 40px;
        }
        @include layout(767) {
            margin-left: 40px;
            margin-top: 40px;
        }
    }
}
.philosophy-group-wrap {
    position: absolute;
    top: 0;
    left: 85px;
    @include layout(1100) {
        left: 0;
    }
    @include layout(767) {
        right: 0;
        width: 320px;
        margin: auto;
    }
}