/* header styles*/
.logo-block {
    a {
        display: inline-block;
        img {
            @include layout(767) {
                width: 108px;
                height: 25px;
            }
        }
    }
}
.hamburger-block {
    padding: 10px;
    cursor: pointer;
    img {
        @include layout(767) {
            width: 15px;
            height: 14px;
        }
    }
}
.header-group {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 40px 30px;
    @include layout(767) {
        padding: 15px 10px 10px 20px;
        align-items: center;
    }
}
.menu-list {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    background-color: #57595f;
    width: 370px;
    &.show {
        display: block;
    }
    @include layout(767) {
        position: fixed;
        height: 100%;
        overflow: auto;
    }
    ul {
        padding-top: 105px;
        padding-bottom: 50px;
        padding-left: 100px;
        @include layout(767) {
            padding-top: 75px;
        }
        li {
            font-size: 20px;
            color: #89b259;
            font-family: $reg;
            padding: 14px 0;
            @include layout(767) {
                font-size: 16px;
            }
            &.active {
                color: #fafbf8;
                border-right: 8px solid #fff;
            }
            &+ li {
                margin-top: 25px;
                @include layout(767) {
                    margin-top: 5px;
                }
            }
        }
    }
    .menu-close {
        width: 18px;
        height: 18px;
        padding: 20px;
        box-sizing: content-box;
        position: absolute;
        right: 40px;
        top: 30px;
        @include layout(767) {
            right: 10px;
            top: 10px;
        }
        img {
            width: 100%;
        }
    }
}