@font-face {
    font-family: lato-regular;
    src: url('../fonts/lato-regular.eot');
    src: url('../fonts/lato-regular.eot') format('embedded-opentype'),
         url('../fonts/lato-regular.woff2') format('woff2'),
         url('../fonts/lato-regular.woff') format('woff'),
         url('../fonts/lato-regular.ttf') format('truetype'),
         url('../fonts/lato-regular.svg#lato-regular') format('svg');
}
@font-face {
    font-family: lato-light;
    src: url('../fonts/lato-light.eot');
    src: url('../fonts/lato-light.eot') format('embedded-opentype'),
         url('../fonts/lato-light.woff2') format('woff2'),
         url('../fonts/lato-light.woff') format('woff'),
         url('../fonts/lato-light.ttf') format('truetype'),
         url('../fonts/lato-light.svg#lato-light') format('svg');
}
@font-face {
    font-family: lato-bold;
    src: url('../fonts/lato-bold.eot');
    src: url('../fonts/lato-bold.eot') format('embedded-opentype'),
         url('../fonts/lato-bold.woff2') format('woff2'),
         url('../fonts/lato-bold.woff') format('woff'),
         url('../fonts/lato-bold.ttf') format('truetype'),
         url('../fonts/lato-bold.svg#lato-bold') format('svg');
}
@font-face {
    font-family: raleway-medium;
    src: url('../fonts/raleway-medium.eot');
    src: url('../fonts/raleway-medium.eot') format('embedded-opentype'),
         url('../fonts/raleway-medium.woff2') format('woff2'),
         url('../fonts/raleway-medium.woff') format('woff'),
         url('../fonts/raleway-medium.ttf') format('truetype'),
         url('../fonts/raleway-medium.svg#raleway-medium') format('svg');
}
@font-face {
    font-family: raleway-semibold;
    src: url('../fonts/raleway-semibold.eot');
    src: url('../fonts/raleway-semibold.eot') format('embedded-opentype'),
         url('../fonts/raleway-semibold.woff2') format('woff2'),
         url('../fonts/raleway-semibold.woff') format('woff'),
         url('../fonts/raleway-semibold.ttf') format('truetype'),
         url('../fonts/raleway-semibold.svg#raleway-semibold') format('svg');
}