/* customers and partners styles */
.customers-partners {
    display: flex;
    justify-content: flex-end;
    @include layout(1100) {
        justify-content: center;
    }
    @include layout(767) {
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
    }
}
.customers-partners-content {
    width: 300px;
    margin-right: 130px;
    margin-top: 185px;
    @include layout(1200) {
        margin-right: 30px;
        padding-left: 30px;
    }
    @include layout(767) {
        margin-right: 0;
        margin-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .section-header {
        border-bottom: 1px solid #7daa48;
        display: flex;
        align-items: flex-end;
        position: relative;
        h4 {
            font-size: 40px;
            color: #5a5e56;
            font-family: $reg;
            display: inline-block;
            line-height: 40px;
            padding-bottom: 10px;
            @include layout(1100) {
                font-size: 30px;
                line-height: 32px;
            }
            @include layout(767) {
                font-size: 21px;
                padding-bottom: 5px;
                line-height: 24px;
            }
        }
        img {
            display: inline-block;
            position: absolute;
            right: 0;
            bottom: 0;
            transform: rotate(31deg);
            @include layout(767) {
                width: 60px;
                height: 60px;
                transform: rotate(6deg);
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        color: #585858;
        font-family: $reg;
        @include layout(767) {
            font-size: 14px;
            line-height: 18px;
        }
    }
}
.customers-partners-media-block {
    width: 745px;
    background-color: #ddf3fb;
    overflow: hidden;
    padding-bottom: 140px;
    margin-top: -1px;
    @include layout(1100) {
        width: 430px;  
        padding-bottom: 90px;
    }
    @include layout(767) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 40px;
        padding-top: 10px;
    }
}
.compaines-block {
    margin-left: 50px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 80px;
    @include layout(1100) {
        margin-left: 0;
        display: block;
        text-align: center;
        & > img {
            width: 144px;
        }
    }
    @include layout(767) {
        margin-left: 0;
        margin-top: 45px;
        display: block;
        & > img {
            width: 100px;
        }
    }
}
.compaines-group {
    padding-top: 80px;
    @include layout(1100) {
        padding-top: 20px;
    }
    @include layout(767) {
        padding-top: 0;
        text-align: center;
    }
}