.it-wrap {
    .services-content-wrap h4 {
        color: #227d96;
        max-width: 270px;
    }
	.about-us-media-block {
	    background-color: #d0e6b3;
        padding-top: 30px;
    	padding-bottom: 200px;
    	@include layout(1100) {
    		padding-top: 0px;
    		padding-bottom: 110px;
    	}
    	@include layout(767) {
    		padding-top: 10px;
    		padding-bottom: 110px;
    	}
	}
	.about-us-rows-group {
		@include layout(1100) {
		    margin-top: -20px;
    	}
	}
	.about-us-column {
	    background-color: #e2ecd6;
		&.red {
		    border: 2px solid #df3c4d;
		}
		&.empty {
			background-color: transparent;
		}
	}
	.services-us-media-block {
		background-color: #fffaad;
	}
	.section-header {
		img {
			display: none;
		}
	}
	.hero-media-mobile {
		@include layout(767) {
		    width: 100%;
		    padding: 0;
		}
		img {
			@include layout(767) {
				display: block;
			    margin-bottom: -1px;
			}
		}
	}
	.hero-block {
		@include layout(767) {
			position: relative;
		}
	}
	.hero-content {
		@include layout(767) {
			position: absolute;
		    top: 74%;
		    padding: 0 35px;
		    margin-top: 0;
		}
	}
	.about-us {
		@include layout(767) {
	    	background-color: #d0e6b3;
			margin-top: 0;
		}
	}
	.about-us-content {
		margin-top: 215px;
		@include layout(767) {
		    margin-top: 10px;
		    margin-bottom: 15px;
		}
	}
	.services-us {
		@include layout(767) {
	    	background-color: #fffaad;
		}
	}
	.services-us-content {
		margin-top: 240px;
		@include layout(767) {
		    margin-top: 40px;
		    margin-bottom: 35px;
		}
	}
	.approach-us {
		@include layout(767) {
    	    background-color: #d3e6b3;
		}
	}
	.approach-us-content {
		margin-top: 230px;
		@include layout(767) {
		    margin-top: 40px;
		    margin-bottom: 25px;
		}
	}
	.customers-partners-content {
	    margin-top: 235px;
		@include layout(767) {
	        margin-top: 40px;
		}
	}
	.customers-partners {
		@include layout(767) {
	        background-color: #ddf3fb;
		}
	}
	.founders {
		@include layout(767) {
	    	background-color: #d0e6b3;
		}
	}
	.founders-content {
		margin-top: 240px;
		@include layout(767) {
		    margin-top: 40px;
		    margin-bottom: 15px;
		}
	}
	.philosophy {
		@include layout(767) {
    	    background-color: #fffaad;
		}
	}
	.philosophy-content {
	    margin-top: 220px;
		@include layout(767) {
			margin-top: 40px;
		    margin-bottom: 35px;
		}
	}
	.get-in-touch {
		@include layout(767) {
    	    background-color: #e4e7ea;
		}
	}
	.get-in-touch-content {
	    margin-top: 230px;
	    @include layout(767) {
	        margin-top: 40px;
		}
	}
	.get-in-touch-address {
		@include layout(767) {
    	    padding-right: 10px;
		}
	}
}